<template>
  <el-drawer
    :title="title"
    :visible.sync="visible"
    direction="rtl"
    :before-close="handleClose"
    :append-to-body="true"
    size="60%"
    class="drawer"
  >
    <div class="container">
      <el-descriptions
        class="desc-list"
        v-for="item in refundList"
        :key="item.id"
      >
        <el-descriptions-item label="退款单号">{{
          item.refundNo
        }}</el-descriptions-item>
        <el-descriptions-item label="退款金额">{{
          item.refundAmt
        }}</el-descriptions-item>
        <el-descriptions-item label="退款时间">{{
          item.createTime
        }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </el-drawer>
</template>

<script>
import { getRefundList } from '@/api/api'
export default {
  data () {
    return {
      title: '退款单列表',
      visible: false,
      refundList: []
    }
  },
  methods: {
    show (billId) {
      this.visible = true
      getRefundList({ billId }).then(res => {
        if (res.code === 0) {
          res.data.forEach(item => {
            item.refundAmt = (item.refundAmt * 0.01).toFixed(2)
            console.log('🚀 ~ file: OrderRefoundList.vue ~ line 46 ~ getRefundList ~ item', item)
          })
          this.refundList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleClose () {
      this.visible = false
    }
  },
}
</script>

<style lang="scss" scoped>
.container{
  padding: 0 20px;
}
</style>
