<template>
  <div>
    <div class="search-area">
      <el-form ref="form" :model="queryParams" inline @submit.native.prevent @keyup.enter.native="onSearch">
        <el-form-item label="商户名称">
          <el-input v-model="queryParams.customerName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            @click="onReset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="action-area">
      <el-button type="primary" @click="onAppend">
        <i class="el-icon-plus"></i> 添加账号</el-button
      >
    </div>
    <el-table v-loading="loading" :data="tableData" border style="width: 100%">
      <el-table-column
        align="center"
        prop="customerName"
        label="商户名称"
        fixed
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="userName"
        label="账号"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="phone"
        label="手机号"
      ></el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <i
            class="icon-class el-icon-edit"
            style="font-size: 16px"
            @click="onEdit(scope.row)"
          ></i>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="jumper, prev, pager, next, sizes, total"
        :total="pagination.total"
        @current-change="onPageChange"
        @size-change="onPageSizeChange"
        :current-page="queryParams.page"
      >
      </el-pagination>
    </div>
    <AccountModule @ok="loadData" ref="append"></AccountModule>
  </div>
</template>

<script>
import { getUserList } from '@/api/api'
import AccountModule from './modules/AccountModule.vue'
import {
  getToken,
} from '@/utils/auth'
export default {
  data () {
    return {
      pagination: {
        total: 100,
      },
      loading: false,
      tableData: [],
      queryParams: { customerName: '', page: 1, size: 10, }
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    onAppend () {
      this.$refs.append.show()
    },
    onEdit (record) {
      this.$refs.append.edit(record)
    },
    onPageChange (e) {
      this.queryParams.page = e
      this.loadData()
    },
    onPageSizeChange (e) {
      this.queryParams.size = e
      this.loadData()
    },
    onSearch () {
      this.queryParams.page = 1
      this.loadData()
    },
    onReset () {
      Object.keys(this.queryParams).forEach(key => {
        if (key !== 'page' && key !== 'size') {
          this.queryParams[key] = ''
        }
      })
      this.dateTime = ''
      this.queryParams.page = 1
      this.loadData()
    },
    loadData () {
      getUserList({
        customerName: this.queryParams.customerName,
        pageNum: this.queryParams.page,
        pageSize: this.queryParams.size,
        userName: getToken()
      }).then(res => {
        if (res.code === 0) {
          this.tableData = res.data
          this.pagination = {
            total: parseInt(res.count),
            sumtotal: parseInt(res.sumtotal)
          }
        }
      })
    }
  },
  components: {
    AccountModule
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/search.scss';

.pagination {
  margin-top: 20px;
  padding-bottom: 30px;
  float: right;
}
.icon-class {
  cursor: pointer;
  color: #409eff;
}
.action-area {
  padding-bottom: 20px;
}
</style>
