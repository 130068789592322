<template>
  <el-drawer
    :title="title"
    :visible.sync="visible"
    direction="rtl"
    :before-close="handleClose"
    size="60%"
    class="drawer"
  >
    <div class="container" v-loading="loading">
      <el-form
        ref="formRef"
        :model="model"
        :rules="fromRules"
        label-width="120px"
      >
        <el-descriptions class="desc-list">
          <el-descriptions-item label="订单总金额">{{
            detail.billTotalAmt
          }}</el-descriptions-item>
          <el-descriptions-item label="剩余可退金额">{{
            detail.surplusAmt
          }}</el-descriptions-item>
          <el-descriptions-item label="已退金额">
            <a href="javascript:;" @click="onShowRefundListModule">{{
              detail.returnTotalAmount
            }}</a>
          </el-descriptions-item>
        </el-descriptions>
        <el-form-item label="退款金额">
          <el-input-number
            v-model="model.refundAmt"
            :step="1"
            :precision="2"
            :max="detail.surplusAmt"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('ruleForm')"
            >退款</el-button
          >
          <el-button @click="handleClose">取消</el-button></el-form-item
        >
      </el-form>
    </div>
    <order-refund-list ref="refundListRef"></order-refund-list>
  </el-drawer>
</template>

<script>
import OrderRefundList from './OrderRefoundList.vue'
import { getRefundInitData, saveRefundOrder } from '@/api/api'
export default {
  components: {
    OrderRefundList,
  },
  data () {
    return {
      title: '退款',
      visible: false,
      loading: false,
      model: {
        refundAmt: 1
      },
      detail: {},
      fromRules: {},
    }
  },
  methods: {
    show (options) {
      this.visible = true
      this.model.billId = options.BillID
      this.loading = true
      this.initData()
    },
    onSubmit () {
      if (this.model.refundAmt <= 0) {
        this.$message.warning('退款金额不能小于0.01元')
        return false
      }
      this.$messageBox.confirm('您正在进行退款操作，是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        this.loading = true
        saveRefundOrder(this.model).then(res => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success('退款成功')
            this.initData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    initData () {
      this.loading = true
      getRefundInitData({ billId: this.model.billId }).then(res => {
        if (res.code === 0) {
          this.detail = res.data
          if (this.model.refundAmt > res.data.surplusAmt) {
            this.model.refundAmt = this.detail.surplusAmt
          }
          if (res.data.surplusAmt === 0) {
            this.model.refundAmt = 0
          }
          this.loading = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    cancel () { },
    handleClose () {
      this.visible = false
      this.model = {
        refundAmt: 1
      }
    },
    onShowRefundListModule () {
      if (this.detail.returnTotalAmount <= 0) {
        return false
      }
      this.$refs.refundListRef.show(this.model.billId)
    }
  },

}
</script>

<style lang="scss" scoped>
.desc-list {
  padding-left: 40px;
  margin-bottom: 16px;
}
.container {
  width: 60%;
}
</style>
