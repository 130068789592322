<template>
  <div>
    <!-- 搜索区域 -->
    <div class="search-area">
      <el-form
        ref="form"
        :model="queryParams"
        @keyup.enter.native="onSearch"
        inline
      >
        <el-form-item label="商户名称">
          <el-input v-model="queryParams.customerName"></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input v-model="queryParams.orderNo"></el-input>
        </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker
            v-model="dateTime"
            :allowClear="false"
            @change="onTimeChange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select v-model="queryParams.status" placeholder="请选择">
            <el-option
              v-for="item in payStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款类型">
          <el-select v-model="queryParams.payScene" placeholder="请选择">
            <el-option
              v-for="item in payStyleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款方式">
          <el-select v-model="queryParams.billRoot" placeholder="请选择">
            <el-option
              v-for="item in billRootList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="花呗分期数">
          <el-select v-model="queryParams.num" placeholder="请选择">
            <el-option
              v-for="item in fqNumList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="贴息方式">
          <el-select v-model="queryParams.hbfqPercent" placeholder="请选择">
            <el-option
              v-for="item in discountInterest"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            @click="onReset"
            >重置</el-button
          >
          <el-button type="primary" @click="onExport"
            ><Icon className="export-icon" iconClass="export"></Icon
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="action-area">
      <span style="color: #888"
        >合计金额：{{ pagination.sumtotal || '0.00' }}</span
      >
    </div>
    <!-- 表格区域 -->
    <el-table v-loading="loading" :data="tableData" border style="width: 100%">
      <el-table-column
        align="center"
        prop="CustomerName"
        label="商户名称"
        fixed
        width="180"
      >
      </el-table-column>
      <!-- <el-table-column prop="name" label="商户拥有人名称" width="180"></el-table-column> -->
      <el-table-column
        align="center"
        prop="customerNames"
        width="100"
        label="子商户名称"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="BillTotal"
        width="120"
        label="支付金额"
      >
      </el-table-column>
      <el-table-column align="center" prop="username" width="100" label="姓名">
      </el-table-column>
      <el-table-column
        align="center"
        prop="ThirdBillID"
        width="180"
        label="三方订单号"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="BillID"
        width="180"
        label="知付订单号"
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="BillDateTime"
        width="180"
        label="订单创建时间"
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="BillRoot"
        width="180"
        label="支付方式"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.Hbfqnum && scope.row.Hbfqnum !== '0'">
            <Icon className="svg-icon hb" iconClass="pay-hb"></Icon>花呗分期
          </div>
          <div v-else-if="scope.row.BillRoot === '支付宝'">
            <Icon className="svg-icon " iconClass="ali-blue"></Icon>支付宝
          </div>

          <div v-else-if="scope.row.BillRoot === '微信'">
            <Icon className="svg-icon" iconClass="wx"></Icon>微信
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="status"
        width="180"
        label="支付状态"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="danger">失败</el-tag>
          <el-tag v-else-if="scope.row.status === 1">成功</el-tag>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="telephone" width="120" label="电话">
      </el-table-column>
      <el-table-column align="center" prop="remarks" width="180" label="备注">
      </el-table-column>

      <el-table-column
        align="center"
        prop="Hbfqnum"
        width="80"
        label="分期数"
      ></el-table-column>
      <el-table-column align="center" width="160" label="贴息方式">
        <template slot-scope="scope">
          <el-tag
            v-if="
              (scope.row.BillPayScene === '花呗分期' &&
                scope.row.proportion === 7.5) ||
              scope.row.proportion === 4.5
            "
            >商户贴息</el-tag
          >
          <el-tag
            type="danger"
            v-else-if="
              scope.row.BillPayScene === '花呗分期' &&
              scope.row.proportion === 0
            "
            >客户贴息</el-tag
          >
          <el-tag
            type="warning"
            v-else-if="
              scope.row.BillPayScene === '花呗分期' &&
              scope.row.proportion !== null
            "
            >部分贴息</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="thirdOrderNo"
        width="180"
        label="商户订单号"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="serviceMoney"
        width="120"
        label="商户分期手续费"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="clientMoney"
        width="120"
        label="客户分期手续费"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="serveMoney"
        width="120"
        label="支付手续费"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="interest"
        width="120"
        label="总手续费"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="realityPrice"
        width="120"
        label="实际到账金额"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="InstalmentInterest"
        width="180"
        label="分期贴息"
      >
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onShowDetail(scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页区域 -->
    <div class="pagination">
      <el-pagination
        background
        layout="jumper, prev, pager, next, sizes, total"
        :total="pagination.total"
        @current-change="onPageChange"
        @size-change="onPageSizeChange"
        :current-page="queryParams.page"
      >
      </el-pagination>
    </div>
    <OrderDetailModule ref="detail"></OrderDetailModule>
    <order-refund-module ref="refundRef"></order-refund-module>
  </div>
</template>

<script>
import { getOrderList, baseUrl } from '@/api/api'
import { formatDate } from '@/utils/utils'
import { MessageBox } from 'element-ui'
import OrderDetailModule from './modules/OrderDetailModule.vue'
import OrderRefundModule from './modules/OrderRefundModule.vue'
import {
  getToken,
} from '@/utils/auth'
export default {
  data () {
    return {
      queryParams: { status: 1, payScene: 'p', customerName: '', num: '', orderNo: '', billRoot: '', beginDate: '', endDate: '', page: 1, size: 10, userName: getToken(), },
      tableData: [],
      pagination: {},
      dateTime: [],
      loading: false,
      payStatusList: [{ label: '全部', value: '', key: 0, }, { label: '成功', value: 1, key: 2, }, { label: '失败', value: 0, key: 1, }],
      billRootList: [{ label: '全部', value: '', key: 0, }, { label: '支付宝扫码', value: 'AliPay', key: 2, }, { label: '微信扫码', value: 'WeChat', key: 1, }],
      payStyleList: [{ label: '全部', value: '', key: 0, }, { label: '全款支付', value: 'c', key: 2, }, { label: '花呗分期', value: 'p', key: 1, }],
      fqNumList: [{ label: '全部', value: '', key: 0, }, { label: '6期', value: 6, key: 2, }, { label: '12期', value: 12, key: 1, }],
      discountInterest: [{ label: '全部', value: '', key: 0, }, { label: '商贴', value: 100, key: 2, }, { label: '客贴', value: 0, key: 1, }]
    }
  },
  components: {
    OrderDetailModule,
    OrderRefundModule,
  },
  mounted () {
    this.queryParams.beginDate = formatDate(new Date(), 'yyyy-MM-dd') + ' 00:00:00'
    this.dateTime = [formatDate(new Date(), 'yyyy-MM-dd') + ' 00:00:00', formatDate(new Date(), 'yyyy-MM-dd') + ' 23:59:59']
    this.loadData()
  },
  methods: {
    /**
     * 显示详情
     */
    onShowDetail (record) {
      this.$refs.detail.show(record)
    },
    /**
     * 导出数据
     */
    onExport () {
      MessageBox.confirm('您将导出筛选日期的全部数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let cTime = this.queryParams.beginDate
        let eTime = this.queryParams.endDate
        if (!cTime) {
          cTime = formatDate(new Date(), 'yyyy-MM-dd') + ' 00:00:00'
          eTime = formatDate(new Date(), 'yyyy-MM-dd') + ' 23:59:59'
          this.queryParams.beginDate = cTime
          this.queryParams.endDate = eTime
        }
        const parms = Object.keys(this.queryParams).map(key => {
          return key + '=' + this.queryParams[key]
        }).join('&')
        window.open(baseUrl + '/bms/jypay/bill/excel?' + parms)
        // console.log(baseUrl)
        // window.open('https://localhost:51003' + '/api/jypay/bill/excel?' + parms)
      })
    },
    onReset () {
      Object.keys(this.queryParams).forEach(key => {
        if (key !== 'page' && key !== 'size' && key !== 'userName' && key !== 'beginDate' && key !== 'endDate') {
          this.queryParams[key] = ''
        }
      })
      this.dateTime = [formatDate(new Date(), 'yyyy-MM-dd') + ' 00:00:00', formatDate(new Date(), 'yyyy-MM-dd') + ' 23:59:59']
      this.queryParams.page = 1
      this.loadData()
    },
    onTimeChange (e) {
      if (e) {
        this.queryParams.beginDate = formatDate(new Date(e[0]).getTime(), 'yyyy-MM-dd hh:mm:ss')
        this.queryParams.endDate = formatDate(new Date(e[1]).getTime(), 'yyyy-MM-dd hh:mm:ss')
      } else {
        this.queryParams.beginDate = ''
        this.queryParams.endDate = ''
      }
    },
    onSearch () {
      this.queryParams.page = 1
      this.loadData()
    },
    /**
     * 每页显示条数发生改变
     */
    onPageSizeChange (e) {
      this.queryParams.size = e
      this.loadData()
    },
    /**
     * 翻页
     */
    onPageChange (e) {
      this.queryParams.page = e
      this.loadData()
    },
    loadData () {
      this.loading = true
      getOrderList(this.queryParams).then(res => {
        this.loading = false
        if (res.code === 0) {
          if (Array.isArray(res.data)) {
            this.parseData(res.data)
            this.tableData = res.data
          }
          this.pagination = {
            total: parseInt(res.total),
            sumtotal: parseFloat(res.sumtotal)
          }
        } else {
          this.tableData = []
          this.pagination.total = 0
        }
      })
    },
    parseData (scrope) {
      scrope.forEach(item => {
        let total = 0
        if ('amountPayable' in item && item.amountPayable > 0) {
          total = Math.round(item.amountPayable * 100)
        } else {
          total = Math.round(item.BillTotal * 100)
        }
        const poundage = total * (item.payRate * 1000) // 0.3分利
        item.serveMoney = (poundage * 0.00001).toFixed(2)
        if (item.BillPayScene === '花呗分期') {
          // splitHbfqNumBearCost(item);
          const stagingNum = parseInt(item.Hbfqnum) // 分几期
          if (!item.proportion) {
            if (stagingNum === 6) {
              if (item.HbfqPart === '商家承担100%') {
                item.proportion = 4.5
                item.clientRate = 0
              } else {
                item.proportion = 0
                item.clientRate = 4.5
              }
            } else {
              if (item.HbfqPart === '商家承担100%') {
                item.proportion = 7.5
                item.clientRate = 0
              } else {
                item.proportion = 0
                item.clientRate = 7.5
              }
            }
          } else {
            if (stagingNum === 6) {
              item.clientRate = (4.5 - item.proportion).toFixed(2)
            } else {
              item.clientRate = (7.5 - item.proportion).toFixed(2)
            }
          }
          const interest = (total * this.judgeRate(stagingNum))

          const serviceInterest = (item.proportion * 10) * total
          // 商贴
          item.serviceMoney = (serviceInterest * 0.00001).toFixed(2)
          // 客贴
          item.clientMoney = ((interest - serviceInterest) * 0.00001).toFixed(2)
          const realityPrice = (total * 1000) - poundage - serviceInterest
          item.realityPrice = (realityPrice * 0.00001).toFixed(2)
          // 手续费
          item.interest = ((interest + poundage) * 0.00001).toFixed(2)
          item.InstalmentInterest = `商贴:${item.proportion} 客贴:${item.clientRate}`
        } else {
          // item.InstalmentInterest = "无"
          // 手续费
          item.interest = (poundage * 0.00001).toFixed(2)
          item.serviceMoney = '0.00'
          item.clientMoney = '0.00'
          // 实际到账
          item.realityPrice = ((total * 1000 - poundage) * 0.00001).toFixed(2)
        }
        item.statusStr = item.status === 1 ? '成功' : '失败'
      })
    },
    /**
     *
     * @param {分期数} num
     */
    judgeRate (num) {
      switch (num) {
        case 3: {
          return 18
        }
        case 6: {
          return 45
        }
        case 12: {
          return 75
        }
      }
      return 1
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.status === 0) {
        return 'warning-row'
      } else if (row.status === 1) {
        return 'success-row'
      }
      return ''
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/search.scss';
.pagination {
  margin-top: 20px;
  padding-bottom: 30px;
  float: right;
  display: flex;
  align-items: center;
}
::v-deep .el-table .warning-row {
  background: #fff4e4;
}

.svg-icon {
  font-size: 22px;
  margin-right: 6px;
  &.hb {
    font-size: 18px;
  }
}
.export-icon {
  font-size: 16px;
}
.action-area {
  padding-bottom: 20px;
  margin-top: 10px;
}
</style>
