<template>
  <el-drawer
    :title="title"
    :visible.sync="visible"
    direction="rtl"
    :before-close="handleClose"
    size="60%"
    class="drawer"
  >
    <el-form
      label-width="100px"
      ref="formRef"
      :model="model"
      :rules="formRules"
    >
      <el-form-item label="商户" v-if="mode === 0" prop="customerId">
        <el-select
          v-model="model.customerId"
          filterable
          remote
          :remote-method="onMerchantSearch"
          v-loadmore="onMerchantScroll"
        >
          <el-option label="总商户" value=""></el-option>
          <el-option
            v-for="(item, key) in merchantList"
            :label="item.CustomerName"
            :value="item.CustomerID"
            :key="key"
          >
            <span style="float: left">{{ item.CustomerName }}</span>
            <span
              style="
                float: right;
                color: #8492a6;
                font-size: 13px;
                padding-left: 10px;
              "
              >{{ item.CustomerWeChatID }}</span
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户名" prop="userName">
        <el-input v-model="model.userName" :disabled="mode === 1"></el-input>
      </el-form-item>
      <!-- <el-form-item label="手机号">
        <el-input v-model="model.phone"></el-input>
      </el-form-item> -->
      <el-form-item label="密码" prop="userPassword">
        <el-input
          v-model="model.userPassword"
          type="password"
          autocomplete="new-password"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmUserPassword">
        <el-input
          v-model="model.confirmUserPassword"
          autocomplete="new-password"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button type="default" @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
import { getMerchantList, saveUser, updateUser } from '@/api/api'
import {
  getToken,
} from '@/utils/auth'
import { isPhone } from '@/utils/validate'
export default {
  data () {
    return {
      title: '',
      visible: false,
      model: { userName: '', phone: '' },
      merchantList: [],
      mode: 0,
      formRules: {
        customerId: [{ required: true, message: '请选择商户', }],
        userName: [{ required: true, message: '请填写用户名', }],
        // phone: [{ required: true, message: '请填写手机号', }, { validator: this.validPhone }],
        userPassword: [{ required: true, message: '请填写密码', }, { validator: this.validateToNextPassword }],
        confirmUserPassword: [{ required: true, message: '请再次填写密码', }, { validator: this.validConfirmPasswrod }],
      },
      queryParams: {
        userName: getToken(),
        customerName: '',
        customerMobile: '',
        page: 1,
        total: 1,
        size: 10,
      },
      updateMethod: saveUser,
    }
  },
  methods: {
    show () {
      this.visible = true
      this.getMerchantList()
    },
    edit (record) {
      this.mode = 1
      this.model.userName = record.userName
      this.model.phone = record.phone
      this.updateMethod = updateUser
      this.visible = true
    },
    handleClose () {
      this.model = { userName: '', phone: '' }
      this.mode = 0
      this.merchantList = []
      this.updateMethod = saveUser
      this.queryParams = {
        userName: getToken(),
        customerName: '',
        customerMobile: '',
        page: 1,
        total: 1,
        size: 10,
      }
      this.$refs.formRef.resetFields()
      this.visible = false
    },
    onSubmit () {
      this.$refs.formRef.validate(valid => {
        if (!valid) { return false }
        this.updateMethod({
          customerId: this.mode === 1 ? '' : this.model.customerId,
          userName: this.model.userName,
          phone: this.model.phone,
          userPassword: this.model.userPassword,
        }).then(res => {
          if (res.code === 0) {
            this.$emit('ok')
            this.handleClose()
            this.$message.success('操作成功')
          } else {
            this.$message.success(res.msg)
          }
        })
      })
    },
    validPhone (rule, value, callback) {
      if (!isPhone(value)) {
        return callback(new Error('手机号格式不正确'))
      }
      callback()
    },
    validateToNextPassword (rule, value, callback) {
      if (value.length < 6 || value.length > 16) {
        callback(new Error('密码长度在6-16'))
      }
      if (value && this.model.confirmUserPassword) {
        this.$refs.formRef.validateField(['confirmUserPassword'])
      }
      callback()
    },
    validConfirmPasswrod (rule, value, callback) {
      if (this.model.userPassword !== value) {
        return callback(new Error('两次输入的密码不一样'))
      }
      callback()
    },
    onMerchantSearch (keyword) {
      this.queryParams.customerName = keyword
      this.queryParams.page = 1
      this.merchantList = []
      this.getMerchantList()
    },
    onMerchantScroll (e) {
      if (this.loadMerchant) { return }
      this.loadMerchant = true
      if (this.queryParams.page < this.queryParams.total) {
        this.queryParams.page++
        this.getMerchantList()
      }
    },
    getMerchantList () {
      getMerchantList(this.queryParams).then(res => {
        this.loadMerchant = false
        if (res.code === 0) {
          res.data = res.data.filter(item => item.AuthoStatus === '0')
          this.merchantList = this.merchantList.concat(res.data)
        }
        this.queryParams.total = Math.ceil(parseInt(res.total) / this.queryParams.page)
      }, () => {
        this.loadMerchant = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form {
  width: 60%;
}
</style>
