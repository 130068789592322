<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    direction="rtl"
    :before-close="handleClose"
    size="60%"
    class="drawer"
  >
    <el-descriptions title="订单信息" :column="2">
      <el-descriptions-item label="商户名称">{{
        detail.CustomerName
      }}</el-descriptions-item>
      <el-descriptions-item label="子商户名称">{{
        detail.customerNames
      }}</el-descriptions-item>
      <el-descriptions-item label="三方订单号">{{
        detail.ThirdBillID
      }}</el-descriptions-item>
      <el-descriptions-item label="商户订单号"
        >{{ detail.thirdOrderNo }}
      </el-descriptions-item>
      <el-descriptions-item label="知付订单号"
        >{{ detail.BillID }}
      </el-descriptions-item>
      <el-descriptions-item label="支付方式">
        <div v-if="detail.Hbfqnum">
          <Icon className="svg-icon hb" iconClass="pay-hb"></Icon>花呗分期
        </div>
        <div v-else-if="detail.BillRoot === '支付宝'">
          <Icon className="svg-icon " iconClass="ali-blue"></Icon>支付宝
        </div>

        <div v-else-if="detail.BillRoot === '微信'">
          <Icon className="svg-icon" iconClass="wx"></Icon>微信
        </div>
      </el-descriptions-item>

      <el-descriptions-item label="支付状态">
        <el-tag v-if="detail.status === 0" size="small" type="danger"
          >失败</el-tag
        >
        <el-tag v-else-if="detail.status === 1" size="small"
          >成功</el-tag
        ></el-descriptions-item
      >
      <el-descriptions-item label="姓名">{{
        detail.username
      }}</el-descriptions-item>

      <!-- <el-descriptions-item label="电话">{{
        detail.telephone
      }}</el-descriptions-item> -->
      <el-descriptions-item label="备注">{{
        detail.remarks
      }}</el-descriptions-item>
      <el-descriptions-item label="支付金额">{{
        detail.BillTotal
      }}</el-descriptions-item>
      <!--
      <el-descriptions-item label="商户分期手续费">{{
        detail.serviceMoney
      }}</el-descriptions-item>
      <el-descriptions-item label="客户分期手续费">{{
        detail.clientMoney
      }}</el-descriptions-item>
      <el-descriptions-item label="支付手续费">{{
        detail.serveMoney
      }}</el-descriptions-item>
      <el-descriptions-item label="总手续费">{{
        detail.interest
      }}</el-descriptions-item>
      <el-descriptions-item label="实际到账金额">{{
        detail.realityPrice
      }}</el-descriptions-item> -->
      <!-- <el-descriptions-item label="分期贴息">{{
        detail.InstalmentInterest
      }}</el-descriptions-item> -->
      <el-descriptions-item label="分期数">{{
        detail.Hbfqnum
      }}</el-descriptions-item>
      <el-descriptions-item label="订单创建时间">{{
        detail.BillDateTime
      }}</el-descriptions-item>
    </el-descriptions>
  </el-drawer>
</template>

<script>
export default {
  data () {
    return {
      drawer: false,
      detail: {},
      title: '订单详情'
    }
  },
  methods: {
    show (options) {
      this.drawer = true
      this.detail = options
      this.title = options.CustomerName
    },
    handleClose () {
      this.drawer = false
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon {
  font-size: 22px;
  margin-right: 6px;
  &.hb {
    font-size: 18px;
  }
}
::v-deep .el-drawer__body {
  padding: 0 20px;
}
::v-deep .el-drawer__header {
  font-size: 18px;
}
</style>
